import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import ContactForm from '../components/organisms/ContactForm'

const NotFoundPage = () => (
  <Layout>
    <SEO title="ENTRY" />
    <ContactForm />
  </Layout>
)

export default NotFoundPage
